import React from 'react'
import SliderItens from './Card'
import portal from '../assets/website.png'

const SliderCards = () => {
  return (
    <div className='flex gap-5 flex-wrap m-5'>
        <SliderItens icon={portal} title='Portal' subtitle='Conte com nosso portal atualizado e prático'/>
        <SliderItens title='Video Aulas'/>
        <SliderItens title=''/>
        <SliderItens />
    </div>
  )
}

export default SliderCards