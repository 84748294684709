import { Outlet } from 'react-router-dom';
import Nav from './components/Nav/Nav';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App font-mono">
      <Nav/>
      <Outlet/>
      <Footer/>
    </div>
  );
}

export default App;
