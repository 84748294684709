import React from "react";
import { Link } from "react-router-dom";
import HLogo from "../HLogo";

function Header() {
  return (
    <div className="flex font-slab bg-cocoa_brown min-h-16 items-center px-4 py-2 justify-center ">
      <ul className="flex gap-10">
        <li className="text-white font-bold">
          <Link to={"/"}>Home</Link>
        </li>

        <li className="text-white font-bold">
          <Link to={"/servicos"}>Serviços</Link>
        </li>
      </ul>

      <HLogo />

      <ul className="flex gap-10">
        <li className="text-white font-bold">
          <a href="/sobre">Sobre</a>
        </li>

        <li className="text-white font-bold">
          <Link to={"/contatos"}>Contatos</Link>
        </li>
      </ul>
    </div>
  );
}

export default Header;
