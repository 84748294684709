import React from "react";
import img from "../assets/reCapa.png";

import ItensLink from "../components/SliderCards";
import CardInfo from "../components/CardInfo";

import teach from "../assets/teaching.png";

import { RiArrowDropRightFill } from "react-icons/ri";

import { BiDoughnutChart } from "react-icons/bi";
import { IoIosWarning } from "react-icons/io";
import { IoIosPaper } from "react-icons/io";
import { BiSolidTrophy } from "react-icons/bi";
import { FaLayerGroup } from "react-icons/fa";

const HomePage = () => {
  return (
    <div className="flex flex-col scroll-smooth bg-gray-100">
      <img
        className="h-[calc(100vh-96px)] object-cover"
        src={img}
        alt="IMAGEM"
      />

      <div className="flex flex-col  items-center mx-5 my-5">
        <h2 className='text-3xl font-slab my-4 after:content-["_L3"] after:text-ut_orange after:font-slab '>
          Nosso sistema
        </h2>
        <hr class="w-80 h-0.5 mx-auto border-0 rounded bg-cocoa_brown mb-4"></hr>
        <h3 className="mb-5 text-gray-500">
          Sua Governança Digital de maneira prática e eficiente.
        </h3>

        <ItensLink />
      </div>

      <div className="relative w-full bg-ft1 bg-cover p-7 drop-shadow-2xl shadow-black shadow-inner">
        <div className="absolute inset-0 bg-delft_blue opacity-85"></div>
        <div className="relative z-10">
          <div className="flex flex-col gap-8 justify-center items-center">
            <h2 className='text-3xl text-white font-slab after:content-["_L3"] after:text-cocoa_brown after:font-slab '>
              Benefícios do nossos usuários
            </h2>
            <hr class="w-32 h-0.5 mx-auto border-0 rounded bg-cocoa_brown"></hr>
            <h3 className=" text-white font-slab mx-60 text-center">
              Além de oferecer sistemas confiáveis e compatíveis com as leis, um
              atendimento ágil e suporte de alta qualidade, a L3 Solutions
              também proporciona aos seus clientes serviços exclusivos.
            </h3>

            <div className="flex gap-16 flex-wrap justify-center items-center">
              <CardInfo
                icon={teach}
                text="Videoaulas que auxiliam os clientes no esclarecimento de dúvidas sobre procedimentos."
              />
              <CardInfo
                icon={teach}
                text="Videoaulas que auxiliam os clientes no esclarecimento de dúvidas sobre procedimentos."
              />
              <CardInfo
                icon={teach}
                text="Videoaulas que auxiliam os clientes no esclarecimento de dúvidas sobre procedimentos."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-around w-full p-5 items-center font-slab ">
        <span>
          L3 Solutions é a solução completa para todas as suas necessidades de
          contratações governamentais
        </span>

        <button className="p-3 bg-ut_orange rounded-xl text-white font-bold">
          Saiba Mais
        </button>
      </div>

      <div className="bg-gray-200">
        <section class="mb-20 mt-20 text-center px-20">
          <div class="flex justify-center ">
            <div class="max-w-[700px] text-center">
              <h2 class="mb-10 text-center flex gap-3 text-3xl font-bold">
                <p>Para nossos </p>
                <u class="text-delft_blue">Fornecedores</u>
              </h2>
            </div>
          </div>

          <div class="grid gap-x-6 md:grid-cols-1 lg:grid-cols-3 lg:gap-x-20">
            <div class="mb-12 min-w-[310px]">
              <div class="mb-6 inline-block rounded-full bg-primary-100 text-primary shadow-sm">
                <BiDoughnutChart color="orange" size={80} />
              </div>
              <h5 class="mb-4 text-xl font-bold">
                Pregão e Dispensa Eletrônica
              </h5>
              <ul class="text-sm inline-block text-gray-800 text-left">
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Consulta de processos
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Participação em pregões eletrônicos
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Participação em dispensas eletrônicas
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Processo automatizado
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Impugnação de editais
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Solicitação de esclarecimentos
                </li>
              </ul>
            </div>

            <div class="mb-12 min-w-[310px]">
              <div class="mb-6 inline-block rounded-full bg-primary-100 text-primary shadow-sm">
                <IoIosWarning color="orange" size={80} />
              </div>
              <h5 class="mb-4 text-xl font-bold">Assinatura Eletrônica</h5>
              <ul class="text-sm inline-block text-gray-800 text-left">
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Assinatura eletrônica de contratos e ARP
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Atesto de recebimento de OF/OS
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Atesto de recebimento de notificações
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Assinatura das convocações para assinatura do contrato
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Proposta de preços
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Pesquisas de Preços
                </li>
              </ul>
            </div>

            <div class="mb-12 min-w-[310px]">
              <div class="mb-6 inline-block rounded-full bg-primary-100 text-primary shadow-sm">
                <FaLayerGroup color="orange" size={80} />
              </div>
              <h5 class="mb-4 text-xl font-bold">CRC Eletrônico</h5>
              <ul class="text-sm inline-block text-gray-800 text-left">
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Envio da documentação via web
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Emissão de CRC e controle de prazos
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Alerta de vencimento das certidões
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Emissão de certidão de regularidade fiscal
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Registro e acompanhamento de sanções
                </li>
              </ul>
            </div>

            <div class="mb-12 min-w-[310px]">
              <div class="mb-6 inline-block rounded-full bg-primary-100 text-primary shadow-sm">
                <BiSolidTrophy color="orange" size={80} />
              </div>
              <h5 class="mb-4 text-xl font-bold">Gestão de Contratos</h5>
              <ul class="text-sm inline-block text-gray-800 text-left">
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill />
                  Notificações
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill />
                  Acompanhamento da execução do contrato
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill />
                  Envio de notas fiscais
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill />
                  Envio da documentação de regularidade fiscal
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill />
                  Alertas de encerramento do prazo contratual
                </li>
              </ul>
            </div>

            <div class="mb-12 min-w-[310px]">
              <div class="mb-6 inline-block rounded-full bg-primary-100 text-primary shadow-sm">
                <IoIosPaper color="orange" size={80} />
              </div>
              <h5 class="mb-4 text-xl font-bold">Gestão da ARP</h5>
              <ul class="text-sm inline-block text-gray-800 text-left">
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill />
                  Notificações
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill />
                  Gerenciamento da ARP
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill />
                  Alertas de encerramento de prazo
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill />
                  Concessão de adesão a ARP
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill />
                  Controle de saldos
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>

      <div>
        <section class="mb-20 mt-20 text-center px-20">
          <div class="flex justify-center ">
            <div class="max-w-[700px] text-center">
              <h2 class="mb-10 text-center flex gap-3 text-3xl font-bold">
                <p>Para as </p>
                <u class="text-delft_blue">Entidades Públicas</u>
              </h2>
            </div>
          </div>

          <div class="grid gap-x-6 md:grid-cols-1 lg:grid-cols-3 lg:gap-x-20">
            <div class="mb-12 min-w-[310px]">
              <div class="mb-6 inline-block rounded-full bg-primary-100 text-primary shadow-sm">
                <BiDoughnutChart color="orange" size={80} />
              </div>
              <h5 class="mb-4 text-xl font-bold">
                Pregão e Dispensa Eletrônica
              </h5>
              <ul class="text-sm inline-block text-gray-800 text-left">
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Processo administrativo;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Pesquisa de preços integrada;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Acesso à procuradoria;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Geração de editais, anexos e demais peças do processo
                  administrativo;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Publicação do PNCP;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Publicação na plataforma + Brasil;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Publicação no site do TCE;
                </li>
              </ul>
            </div>

            <div class="mb-12 min-w-[310px]">
              <div class="mb-6 inline-block rounded-full bg-primary-100 text-primary shadow-sm">
                <IoIosWarning color="orange" size={80} />
              </div>
              <h5 class="mb-4 text-xl font-bold">Assinatura Eletrônica</h5>
              <ul class="text-sm inline-block text-gray-800 text-left">
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Assinatura eletrônica de contratos;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Assinatura eletrônica de ARP;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Assinatura de editais e demais peças do processo;
                </li>
              </ul>
            </div>

            <div class="mb-12 min-w-[310px]">
              <div class="mb-6 inline-block rounded-full bg-primary-100 text-primary shadow-sm">
                <FaLayerGroup color="orange" size={80} />
              </div>
              <h5 class="mb-4 text-xl font-bold">CRC Eletrônico</h5>
              <ul class="text-sm inline-block text-gray-800 text-left">
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Recebimento da documentação via web;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Emissão de CRC e controle de prazos;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Controle de prazo das certidões;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Emissão de certidão de regularidade fiscal;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Processo de apuração de responsabilidade – PAAR;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Registro e acompanhamento das sanções;
                </li>
              </ul>
            </div>

            <div class="mb-12 min-w-[310px]">
              <div class="mb-6 inline-block rounded-full bg-primary-100 text-primary shadow-sm">
                <BiSolidTrophy color="orange" size={80} />
              </div>
              <h5 class="mb-4 text-xl font-bold">Gestão de Contratos</h5>
              <ul class="text-sm inline-block text-gray-800 text-left">
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Notificações;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Ata de reunião inicial do contrato;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Livro de ocorrência;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Controle de dosimetria das penalizações;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Acompanhamento da execução do contrato;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Recebimento e controle de notas fiscais por contrato;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Recebimento e controle da documentação de regularidade fiscal
                  do contratado;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Alertas de encerramento do prazo contratual;
                </li>
              </ul>
            </div>

            <div class="mb-12 min-w-[310px]">
              <div class="mb-6 inline-block rounded-full bg-primary-100 text-primary shadow-sm">
                <IoIosPaper color="orange" size={80} />
              </div>
              <h5 class="mb-4 text-xl font-bold">Gestão da ARP</h5>
              <ul class="text-sm inline-block text-gray-800 text-left">
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Pesquisa automática;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Margem de corte;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Pesquisa regionalizada;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Pesquisa com base nos preços praticados no setor público;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Pesquisa na internet;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Pesquisa com fornecedores;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Pesquisa em ARP;
                </li>
                <li className="flex items-center pb-2">
                  <RiArrowDropRightFill color="orange" size={20} />
                  Pesquisa junto ao BPS;
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomePage;
