import React from "react";
import teach from "../assets/teaching.png";

const CardInfo = (props) => {
  var icon = props.icon;
  var text = props.text;

  if (icon) {
    icon = props.icon;
  } else {
    icon = teach;
  }

  if (text) {
    text = props.text;
  } else {
    text =
      "Videoaulas que auxiliam os clientes no esclarecimento de dúvidas sobre procedimentos.";
  }

  return (
    <div className="flex flex-col duration-300 hover:-translate-y-4">
      <div className="flex bg-white justify-center items-center min-w-60 min-h-40 rounded-t-lg">
        <img src={props.icon} alt="video" className="w-28 " />
      </div>
      <div className="bg-cocoa_brown max-w-60  p-3">
        <span className="text-xs text-oxford_blue font text-center line-clamp-5  ">
          {props.text}
        </span>
      </div>
    </div>
  );
};

export default CardInfo;
