import React from 'react'
import Header from './Header'

import { IconContext } from "react-icons";

import { HiPhone } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";

function Nav() {
  return (
    <nav className='flex flex-col w-full '>
      <div className="flex gap-10 bg-delft_blue px-4 py-2 text-xs">
        <div className='flex gap-1 items-center text-white'>
          <IconContext.Provider value={{ color: "white", className: "size-4" }}>
              <HiPhone />
          </IconContext.Provider>
          <p>(85) 991787177</p>
        </div>
        <div className='flex gap-1 items-center text-white'>
          <IconContext.Provider value={{ color: "white", className: "size-4" }}>
                <HiOutlineMail />
          </IconContext.Provider>
          <p>contato_ll@hotmail.com</p>
        </div>
      </div>


      <Header/>
    </nav>
  )
}

export default Nav