import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import HomePage from './pages/HomePage';
import Sobre from './pages/Sobre';
import Contatos from './pages/Contatos';
import Servicos from './pages/Servicos';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App/>}>
        <Route index element={<HomePage/>}/>
        <Route path='/sobre' element={<Sobre/>}/>
        <Route path='/contatos' element={<Contatos/>}/>
        <Route path='/servicos' element={<Servicos/>}/>
      </Route>
    </Routes>
  </BrowserRouter>
);
