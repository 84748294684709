import React from "react";
import HLogo from "../HLogo";

import { HiOutlineMail } from "react-icons/hi";
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { TiArrowRightThick } from "react-icons/ti";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="flex-1 flex-col  text-gray-300">
      <div className="flex bg-delft_blue px-5 py-10 justify-evenly">
        <div className="flex flex-col justify-center text-center max-w-64">
          <h3 className="font-bold text-xl mb-5">Contato</h3>

          <div className="flex items-center gap-2">
            <HiOutlinePhone className="size-5" />
            (85) 991787177
          </div>

          <div className="flex items-center gap-2 ">
            <HiOutlineInformationCircle className="size-5" />
            <Link to={"/contatos"}>Central de atendimento</Link>
          </div>

          <div className="flex items-center gap-2">
            <HiOutlineMail className="size-5" />
            contato_ll@hotmail.com
          </div>
        </div>

        <div className="flex flex-col justify-center text-center max-w-64">
          <h3 className="font-bold mb-5 text-xl">Páginas</h3>

          <div className="flex items-center gap-2">
            <TiArrowRightThick className="size-5" />
            <Link to={"/servicos"}>Serviços</Link>
          </div>
          <div className="flex items-center gap-2">
            <TiArrowRightThick className="size-5" />
            <Link to={"/sobre"}>Sobre</Link>
          </div>
          <div className="flex items-center gap-2">
            <TiArrowRightThick className="size-5" />
            <Link to={"/contato"}>Contato</Link>
          </div>
        </div>
      </div>

      <div className="flex bg-oxford_blue px-5 py-2 items-center gap-10 text-sm">
        <HLogo />

        <p>
          L3 Solutions © Copyright 2023 L3 Solutions Ltda. Todos os direitos
          reservados.
        </p>
      </div>
    </div>
  );
};

export default Footer;
