import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/Logo L3 - horizontal 1322x264.png'

const HLogo = () => {
  return (
    <Link className='mx-32' to={"/"}>
        <img src={logo} alt="logo-horizontal" className='w-64'/>
    </Link>
  )
}

export default HLogo