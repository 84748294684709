import React from 'react'
import { FiPlus } from "react-icons/fi";
import ouvidoria from '../assets/call-center-service-w.png'

const Card = (props) => {

    var title = props.title
    var icon = props.icon
    var subtitle = props.subtitle

    if (icon){
        icon = props.icon
    }else{
        icon = ouvidoria
    }

    if (title){
        title = props.title
    }else{
        title = 'Ouvidoria'
    }

    if (subtitle){
        subtitle = props.subtitle
    }else{
        subtitle = 'Entre em contato com nosso atendimento.'
    }

  return (
    <div className='duration-300 hover:-translate-y-4'>
        <div className="flex flex-col w-52 min-w-52 min-h-80 max-h-80 bg-delft_blue rounded-lg shadow ">
            <h5 className="text-xl font-medium text-white bg-ut_orange w-full text-center p-3 mb-4 rounded-md  ">{title}</h5>

            <div className="flex flex-col h-full items-center justify-center">

                <img className="w-24 h-24 mb-6 rounded-full shadow-lg" src={icon} alt="Ouvidoria"/>
                <span className="text-sm min-h-14 mx-8 text-center text-gray-400 line-clamp-3 ">{subtitle}</span>
                
                <div className="flex mt-4 md:mt-6">
                    <a href="/" className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >Saiba Mais <FiPlus  className='ml-1'/> </a>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Card